<template>
  <v-container fill-height fluid class="pagenotfound--container">
    <v-row align="center" justify="center">
      <v-col align="center">
        <p class="ma-0 text--bold--20 pagenotfound--title--text">
          ตั้งค่ารหัสผ่านใหม่
        </p>
        <p class="ma-0 text--regular--14 pagenotfound--detail--text">
          กรุณาตั้งรหัสผ่านใหม่
        </p>
        <v-container
          class="text-center col-md-12 col-12 col-sm-12 col-12 pagenotfound--btn--container"
        >
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
            class="loginform-gmail-padding"
          >
            <v-row align="center" justify="center">
              <v-col cols="12" sm="12" md="3" align="center">
                <p
                  class="mb-0 text--bold--14 "
                  style="text-align: start; margin-bottom: 6px !important;"
                >
                  รหัสผ่านใหม่
                </p>
                <v-text-field
                  v-model="password"
                  single-line
                  label="รหัสผ่านใหม่"
                  outlined
                  required
                  dense
                  type="password"
                  :rules="passwordRule"
                  class="loginform-gmail-text-field"
                ></v-text-field>

                <p
                  class="mb-0 text--bold--14"
                  style="text-align: start; margin-bottom: 6px !important;"
                >
                  ยืนยันรหัสผ่าน
                </p>
                <v-text-field
                  v-model="rePassword"
                  single-line
                  label="ยืนยันรหัสผ่าน"
                  outlined
                  required
                  dense
                  type="password"
                  :rules="[
                    [v => !!v || 'กรุณายืนยันรหัสผ่าน'],
                    this.password === this.rePassword || 'รหัสผ่านไม่ตรงกัน'
                  ]"
                  class="loginform-gmail-text-field"
                ></v-text-field>
              </v-col>
            </v-row>
            <div v-if="resetPasswordResponse">
              <span class="mb-0 red--text" v-if="resetPasswordResponse.error">{{
                resetPasswordResponse.error.message
              }}</span>
            </div>
          </v-form>
          <v-btn
            width="100%"
            rounded
            color="primary"
            light
            elevation="0"
            @click="onResetPassword"
            class="pagenotfound--btn"
          >
            <span class="reset-password-btn-text">เปลี่ยนรหัสผ่าน</span></v-btn
          >
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
export default {
  created() {
    if (this.$route.params.param) {
      let decodeParam = decodeURIComponent(this.$route.params.param);
      let decodeBase64Param = atob(decodeParam);
      let json = JSON.parse(decodeBase64Param);
      let objParam = { ...json };
      if (objParam.email) {
        this.param = objParam;
      } else {
        this.$router.push("/");
      }
    } else {
      this.$router.push("/");
    }
  },
  data: () => ({
    resetPasswordResponse: null,
    param: null,
    valid: true,
    password: "",
    rePassword: "",
    passwordRule: [
      v => {
        if (v) {
          return (
            (v && v.length >= 8) || "รหัสผ่านต้องมากกว่าหรือเท่ากับ 8 ตัวอักษร"
          );
        } else {
          return !!v || "กรุณาระบุ รหัสผ่าน";
        }
      }
    ]
  }),
  methods: {
    ...mapActions("user", ["resetPassword"]),
    async onResetPassword() {
      if (this.$refs.form.validate()) {
        this.resetPasswordResponse = await this.resetPassword({
          email: this.param.email,
          password: this.password
        });
        if (this.resetPasswordResponse) {
          if (this.resetPasswordResponse.status === 200) {
            localStorage.removeItem("keeplink");
            this.$router.push("/login");
          }
        }
      }
    }
  }
};
</script>

<style scoped>
/* Button Section */
.pagenotfound--btn {
  max-width: 207px !important;
  height: 45px !important;
  border-radius: 23px;
  padding: 12px 59px 12px 61px;
}
.reset-password-btn-text {
  width: 87px;
  height: 21px;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #282828;
}

/* Mobile Section */
@media (max-width: 480px) {
  .pagenotfound--img {
    width: 284px;
    height: 214px;
  }

  .pagenotfound--btn {
    /* padding: 12px 78px 12px 78px !important; */
    /* margin-top: 39.5px; */
    margin-top: 17.5px;
    /* height: 45px !important; */
  }

  .pagenotfound--btn--container {
    /* margin-top: 50px; */
    /* height: 54px; */
    margin-top: 0px;
    padding-bottom: 0px;
  }

  .pagenotfound--title--text {
    margin-top: 50px !important;
    /* margin-top: 37px !important; */
  }

  .pagenotfound--container {
    padding-top: 0px;
    padding-bottom: 273.5px;
    /* padding-top: 31px;
    padding-bottom: 132px; */
  }
  .pagenotfound--detail--text {
    margin-top: 11px !important;
    margin-bottom: 52px !important;
  }
}

@media (min-width: 481px) {
  .pagenotfound--img {
    width: 376px;
    height: 284px;
  }
  .pagenotfound--btn {
    margin-top: 40.5px;
    /* padding: 12px 78px 12px 78px !important; */
    /* height: 45px !important; */
  }

  .pagenotfound--btn--container {
    margin-top: 47px;
  }

  .pagenotfound--title--text {
    margin-top: 46px !important;
  }

  .pagenotfound--container {
    padding-top: 84px;
    padding-bottom: 116px;
  }
  .pagenotfound--detail--text {
    margin-top: 14px !important;
  }
}
</style>
